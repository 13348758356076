import type { AsyncDataRequestStatus } from "#app"

export async function useTableFetch<T>(
  url: string,
  method: "get" | "post",
  filter?: Ref<Record<string, string>>,
  /** Determines how sorting parameters are formatted in the request.
   *   - `"key-direction"`: Sends sorting parameters as `key` and `direction` (e.g., `?key=name&direction=asc`).
   *   - `"sort-with-minus"`: Sends a single `sort` parameter with a minus prefix for descending order (e.g., `?sort=-name`). **/
  sortParamType: "key-direction" | "sort-with-minus" = "key-direction",
) {
  const sortColumn = ref<{ key: string; direction: "asc" | "desc" }>()

  const route = useRoute()
  const page = computed(() => Number(route.query.page ?? 1))

  const params = computed(() => {
    const sortParams =
      sortParamType == "key-direction"
        ? {
            key: sortColumn.value?.key,
            direction: sortColumn.value?.direction,
          }
        : {
            sort: sortColumn.value?.key
              ? `${sortColumn.value.direction == "desc" ? "-" : ""}${sortColumn.value.key}`
              : undefined,
          }
    const baseParams = {
      ...sortParams,
      page: page.value,
    }
    return filter ? { ...filter.value, ...baseParams } : baseParams
  })

  const { devState } = useDeveloperTools()
  if (devState.value.loading) {
    return {
      status: ref<AsyncDataRequestStatus>("pending"),
      data: ref(undefined),
      error: ref(undefined),
      refresh: () => Promise,
      execute: () => Promise,
      clear: () => Promise,
      sortColumn,
    } as const
  }

  const { $api } = useNuxtApp()
  const result = await useAsyncData(
    `${url}-${method}-${page.value}-${JSON.stringify(sortColumn.value)}-${JSON.stringify(params.value)}`,
    async () => {
      return $api<T>(url, {
        method,
        [method == "post" ? "body" : "query"]: params.value,
      })
    },
    {
      watch: [params],
      lazy: true,
    },
  )

  const router = useRouter()
  watch(
    () => filter?.value,
    async () => {
      await router.push({ query: { ...route.query, page: "1" } })
    },
    { deep: true },
  )
  return { ...result, sortColumn }
}
